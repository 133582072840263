

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Exofaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5">
            <Row className="justify-content-center">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about Exosomes Hair Loss & Face Rejuvenation</Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">

              <Col

              >
                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What are exosomes, and how are they used in hair and face treatments?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exosomes are vesicles containing bioactive molecules extracted from salmon. In hair and face
                        treatments, these exosomes are utilized to promote hair growth and rejuvenate facial skin.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How do exosomes stimulate hair growth?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exosomes contain growth factors and nutrients that nourish the scalp and promote the health of
                        hair follicles, leading to improved hair growth and thickness.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What benefits do exosomes offer for facial skin rejuvenation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exosomes can stimulate collagen production, reduce fine lines and wrinkles, and improve overall
                        skin texture and tone when applied to the facial skin.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Are exosomes safe for use in hair and face treatments?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, exosomes are considered safe for hair and face treatments. Since they are derived from
                        natural sources, the risk of adverse reactions is minimal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How long does it take to see results from exosome therapy for hair and face?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Results from exosome therapy may vary, but noticeable improvements in hair growth and skin
                        texture can often be seen within a few weeks to months of treatment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Do exosomes have any side effects when used for hair and face treatments?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Side effects associated with exosome therapy are rare and usually mild. These may include
                        temporary redness or irritation at the application site.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Can exosome therapy be combined with other hair restoration or skin rejuvenation treatments?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, exosome therapy can be combined with other hair restoration or skin rejuvenation treatments
                        to enhance results. Consult with your healthcare provider to determine the best combination for
                        your needs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Are exosomes suitable for all skin types and hair conditions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exosome therapy is generally suitable for all skin types and hair conditions. However,
                        individual factors may influence treatment effectiveness, so consult with a qualified healthcare
                        provider for personalized advice.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How often do I need to undergo exosome therapy sessions for optimal results?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The frequency of exosome therapy sessions may vary depending on individual needs and treatment
                        goals. Your healthcare provider will recommend a personalized treatment plan based on factors
                        such as hair and skin condition.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is exosome therapy for hair and face a permanent solution?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        While exosome therapy can yield long-lasting results, periodic maintenance sessions may be
                        necessary to sustain optimal outcomes. Your healthcare provider can provide guidance on
                        long-term treatment plans.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>


              </Col>


            </Row>


            <br /> <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>


    );
}

